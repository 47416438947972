
import React, { useState, useRef } from 'react';
import './ContactForm.css';
import { Card, Col, Row, Button, Form, Container, ListGroup, Spinner } from 'react-bootstrap';
import { LocationIcon, MessageIcon, PhoneIcon, RightIcon } from '../../assets/icons/icons';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const ContactForm = () => {
    const [pending, setPending] = useState(false);

    const form = useRef();

    const sendEmail = (event) => {
        event.preventDefault();
        setPending(true);
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                toast.success('Email sent successfully!');
                console.log(result.text);
                form.current.reset();
                setPending(false);
            }).catch((error) => {
                toast.error(error.message);
                setPending(false);
            })

    };
    return (
        <div className='position-relative'>
            <Helmet>
                <title>Pipe Scaffolding Rental in Chennai, Cuplock Scaffolding Chennai</title>
                <meta name="description" content="Contact Narayanasamy Industries for inquiries about scaffolding rental services in Chennai. Reach out to us via phone or email, or visit our office at Vanagaram, Chennai." />
            </Helmet>
            <section className="contact-hero">

            </section>
            <section className="py-5 form-sec">
                <Container className='px-xl-5'>
                    <Row className='g-0 px-xl-5'>
                        <Col>
                            <div>
                                <h6 className="hi-txt text-secondary">Hey There</h6>
                                <h2 className="hi-subtxt">LETS GET IN TOUCH</h2>
                            </div>
                            <ListGroup variant="flush" className="as">
                                <ListGroup.Item className='px-xl-0'>
                                    <LocationIcon />
                                    <span className="ms-xl-4 ms-2">Vanagaram,Chennai</span>
                                </ListGroup.Item>
                                <ListGroup.Item className='px-xl-0'>
                                    <PhoneIcon />
                                    <span className="ms-xl-4 ms-2">+91-7200989139, +91-9444861825</span>
                                </ListGroup.Item>
                                <ListGroup.Item className='px-xl-0'>
                                    <MessageIcon />
                                    <span className="ms-xl-4 ms-2">narayanasamyindustries@gmail.com</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </section >
            <div className='card-div'>
                <Card className='contact-card p-4 shadow'>
                    <h1 className='text-white ms-1 mt-3'>CONTACT US</h1>
                    <p className='text-white ms-2 mb-5'>welcome! Welcome to</p>
                    <Form className='px-2' ref={form} onSubmit={sendEmail}>
                        <Form.Group controlId="formfirstname" className="mb-5">
                            <Form.Control name="user_name" type="text" className='field' placeholder="FullName" required />
                        </Form.Group>
                        <Form.Group controlId="formfirstname" className="mb-5">
                            <Form.Control name="mobileno" type="" className='field' placeholder='Mobileno' required />
                        </Form.Group>
                        <Form.Group controlId="formfirstname" className="mb-5">
                            <Form.Control name="user_email" type="email" className='field' placeholder='Your Email' required />
                        </Form.Group>
                        <Form.Group controlId="formfirstname" className="mb-3">
                            <Form.Control as="textarea" rows={4} name="message" className='field' placeholder='Your Message' required />
                        </Form.Group>
                        <Button type="submit" className="bg-white mt-5 mb-4 rounded-5 border-0 px-4 py-2" style={{ color: '#1868fd' }}>
                            {!pending && (
                                <>Send <RightIcon /></>
                            )}
                            {pending && (
                                <span className="">
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden={true}
                                        className="align-baseline"
                                    >
                                        {/* <span className="sr-only">Loading</span>{' '} */}
                                    </Spinner>
                                    &nbsp; Sending...
                                </span>
                            )}
                        </Button>
                    </Form>
                </Card>
            </div>
            <section>
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.555650343811!2d80.1538309!3d13.0745207!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261bb18f6e9b9%3A0x79a7e5b5462cb176!2sNarayanasamy%20Industries!5e0!3m2!1sen!2sin!4v1680856685941!5m2!1sen!2sin" width="100%" height="450"
                        style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>

        </div >
    );
}

export default ContactForm;