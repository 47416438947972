import centring from "./centering.png";
import clientsm from "./clientsm.png";
import clientsmc from "./clientsmc.png";
import clientdta from "./clientdta.png";
import clientjt from "./clientjt.png";
import anitechclient from "./anitechclient.png";
import prakashclient from "./prakashclient.png";
import smrclient from "./smrclient.png";
import clientinnovative from "./clientinnovative.png";
import hero from "./hero.svg";
import man2 from "./man2.png";
import man3 from "./man3.png";
import bulb4 from "./bulb4.png";
import logonew from "./newlogo.png";
import systemupdate from "./system-update.png";
import balanced from "./balanced.svg";
import Asset from './Asset12.svg'
import about from './aboutus.png';
import about2 from './about2.png';
import applogo from './applogo.png';
import footerlogo from "./Orange Round Typography Real Estate Monogram Business Logo.png";
import herosection from "./hero-section.png";
import downarrow from "./down-arrow.png";
import logo from "./logo.png";
import jointpin from "./jointpin.png";
import baseplate from "./baseplate.png";
import casters from "./Casters.png";
import ujack from "./ujack.png";
import scaffoldingcouplar from "./scaffolding-coupler.webp";
import jolly from "./jolly.png";
import baseplate2 from "./baseplate2.png";
import business from "./business.png";
import apspipe from "./apspipe.jpg";
import steel from "./steel.png";
import valve from './valve.png';
import concrete from "./concrete.png";
import adjbaseplate from "./baseplate.png"
import Couplar from "./Couplar.svg";
import wheel from "./wheel.svg";
import board from "./boards.jpg"

const images = {
  board:board,
  logo: logo,
  centring: centring,
  downarrow: downarrow,
  clientdta: clientdta,
  smrclient: smrclient,
  clientinnovative: clientinnovative,
  prakashclient: prakashclient,
  anitechclient: anitechclient,
  clientjt: clientjt,
  clientsm: clientsm,
  clientsmc: clientsmc,
  hero: hero,
  bulb4: bulb4,
  man2: man2,
  man3: man3,
  herosection: herosection,
  logonew: logonew,
  steel: steel,
  valve: valve,
  systemupdate: systemupdate,
  concrete: concrete,
  balanced: balanced,
  Asset: Asset,
  adjbaseplate: adjbaseplate,
  baseplate: baseplate,
  Couplar: Couplar,
  jolly: jolly,
  jointpin: jointpin,
  ujack: ujack,
  wheel: wheel,
  about: about,
  about2: about2,
  footerlogo: footerlogo,
  applogo: applogo,
  casters: casters,
  business: business,
  apspipe: apspipe,
  scaffoldingcouplar: scaffoldingcouplar,
  baseplate2: baseplate2,
};

export default images;
