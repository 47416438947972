
import images from '../assets/images/image';
export const productarray = [
    {
        id: 1,
        productname: 'Base Plate',
        images: images.baseplate,
        description: 'A baseplate is an essential component of scaffolding because it provides a secure and stable foundation for the entire scaffold structure. The baseplate is the point of contact between the scaffold and the ground, and it distributes the weight of the scaffold evenly to prevent it from sinking into the ground or shifting.Without a baseplate, the scaffold could be unstable, making it unsafe for workers to use. In addition, a baseplate helps to prevent damage to the ground underneath the scaffold, which is important for protecting the environment and any underground utilities. Overall, using a baseplate in scaffolding is essential for ensuring the safety of workers and the stability of the scaffold structure.'
    },
    {
        id: 2,
        productname: 'Scaffolding Wheel',
        images: images.casters,
        description: 'Scaffolding wheels, also known as caster wheels, are used to enable easy movement of scaffolding from one location to another. They are attached to the bottom of scaffolding legs and allow workers to move the scaffold around without having to disassemble and reassemble it in a new location.The use of scaffolding wheels can increase efficiency and productivity on job sites, as workers can quickly and easily move the scaffold to where it is needed. This can save time and effort, particularly on larger job sites where the scaffold may need to be moved frequently.'
    },
    {
        id: 3,
        productname: 'Scaffolding U Jack',
        images: images.ujack,
        description: 'A U jack, also known as a universal jack or adjustable base plate, is a component used in scaffolding to provide adjustable height and support for scaffold structures. It is commonly used in combination with a screw jack, which is used to adjust the height of the U jack. The U jack is typically placed at the bottom of a scaffolding leg and provides a stable base for the scaffold structure. The adjustable height feature allows workers to level the scaffolding on uneven surfaces, ensuring the safety and stability of the scaffold.'
    },
    {
        id: 4,
        productname: 'Joint Pin',
        images: images.jointpin,
        description: 'A joint pin is a component used in scaffolding to connect two scaffolding tubes end-to-end, creating a continuous and secure connection. The joint pin is a simple cylindrical rod that fits into the openings at the ends of the two tubes being joined together. Once in place, the joint pin is secured by a locking device or a spring clip. Joint pins are used in both tube and clamp scaffolding and system scaffolding. In tube and clamp scaffolding, the joint pin is used to connect the vertical scaffold tubes to the horizontal scaffold tubes, creating a stable framework for the scaffold structure. In system scaffolding, joint pins are used to connect the horizontal ledgers to the vertical standards, allowing for the assembly of various scaffold configurations.'
    },
    {
        id: 5,
        productname: 'Platform Jolly',
        images: images.jolly,
        description: 'A platform jolly is a type of mobile work platform that is commonly used in scaffolding systems. It is essentially a small scaffold platform mounted on casters or wheels that can be moved easily from one location to another. In scaffolding, platform jollies are typically used as an alternative to ladder access, as they provide a safer and more stable work platform for workers at height. They can also be used to access areas that are difficult to reach with a standard scaffold tower. Platform jollies are available in various sizes and configurations, and some may be adjustable in height to suit different work requirements. They are typically made of lightweight yet durable materials such as aluminum or steel, and may feature guardrails or other safety features to prevent falls or accidents.'
    },
    {
        id: 6,
        productname: 'Scaffolding Couplar',
        images: images.scaffoldingcouplar,
        description: 'A scaffolding coupler, also known as a scaffold clamp, is a component used in scaffolding systems to connect two scaffold tubes together at a right angle or in-line to form a secure joint. Scaffolding couplers come in various shapes and sizes depending on the type of scaffold and the size of the scaffold tube being used. Some common types of scaffolding couplers include swivel couplers, fixed couplers, putlog couplers, and sleeve couplers. Swivel couplers are designed to connect two tubes at any angle and can rotate a full 360 degrees. Fixed couplers are used to connect two tubes in a fixed position, typically at right angles. Putlog couplers are used to connect scaffold tubes to brickwork or other surfaces, and sleeve couplers are used to join tubes end-to-end.'
    },
    {
        id: 7,
        productname: 'Adjustable Base Plate',
        images: images.baseplate2,
        description: 'An adjustable base plate is a component used in scaffolding systems to provide a level and stable base for scaffold standards or uprights. It consists of a metal plate with a threaded shaft that can be adjusted in height to accommodate uneven ground or flooring. Adjustable base plates are typically made of steel or aluminum and are designed to be durable and long-lasting. They may feature a swivel or fixed base depending on the specific application and are often compatible with various types of scaffold systems. To use an adjustable base plate, the threaded shaft is extended or retracted to adjust the height of the scaffold standard or upright. Once the desired height is achieved, the base plate is locked into place using a locking pin or other securing device. This provides a stable and level base for the scaffold structure, ensuring worker safety and preventing accidents.'
    },
    {
        id: 8,
        productname: 'Aps Horizontal and vertical pipe',
        images: images.apspipe,
        description: ' horizontal pipes are used as ledger or horizontal braces that connect the vertical tubes or standards to form a stable and secure scaffold structure. These horizontal pipes are typically made of steel or aluminum and are available in various lengths and diameters.The ledger or horizontal braces play an important role in providing lateral stability to the scaffold structure, preventing it from swaying or collapsing. They are placed at regular intervals across the scaffold and are secured to the vertical standards using couplers or clamps.Horizontal pipes are also used as transoms, which are horizontal pipes that run perpendicular to the ledgers and provide additional support to the scaffold platform. Transoms are placed at regular intervals and are attached to the ledgers using couplers or clamps. In addition to providing stability and support, horizontal pipes in scaffolding are also used as handrails, guardrails, and midrails to prevent falls and ensure worker safety.'
    },
    {
        id: 9,
        productname: 'Scaffolding Boards',
        images: images.board,
        description: ' horizontal pipes are used as ledger or horizontal braces that connect the vertical tubes or standards to form a stable and secure scaffold structure. These horizontal pipes are typically made of steel or aluminum and are available in various lengths and diameters.The ledger or horizontal braces play an important role in providing lateral stability to the scaffold structure, preventing it from swaying or collapsing. They are placed at regular intervals across the scaffold and are secured to the vertical standards using couplers or clamps.Horizontal pipes are also used as transoms, which are horizontal pipes that run perpendicular to the ledgers and provide additional support to the scaffold platform. Transoms are placed at regular intervals and are attached to the ledgers using couplers or clamps. In addition to providing stability and support, horizontal pipes in scaffolding are also used as handrails, guardrails, and midrails to prevent falls and ensure worker safety.'
    }
]