import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Assuming this contains your custom styles
import images from '../../../assets/images/image';

const NavBar = () => {
  const [navState, setNavState] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleNavClick = () => {
    setNavState(!navState);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize); // Listen to window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
    };
  }, []);

  return (
    <Container fluid className="nav-sm mb-1">
      <Navbar id="navbar" className="newNav" expand="lg" expanded={navState}>
        <Navbar.Brand href="/" className="logo-name">
          <img
            id="logo"
            src={images.logo}
            alt="Company Name"
            className='w-100'
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="navbar-toggler"
          aria-controls="basic-navbar-nav"
          onClick={handleNavClick}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`ms-auto ${isMobileView ? 'flex-column' : 'flex-row'}`}>
            <NavLink to="/" className="nav-link nav-pad" onClick={isMobileView ? handleNavClick : null}>
              Home
            </NavLink>
            <NavLink to="/#service" className="nav-link nav-pad" onClick={isMobileView ? handleNavClick : null}>
              Our Service
            </NavLink>
            <NavLink to="/products" className="nav-link nav-pad" onClick={isMobileView ? handleNavClick : null}>
              Our Products
            </NavLink>
            {/* <NavLink to="/projects" className="nav-link nav-pad" onClick={isMobileView ? handleNavClick : null}>
              Our Projects
            </NavLink> */}
            <NavLink to="/about" className="nav-link nav-pad" onClick={isMobileView ? handleNavClick : null}>
              About Us
            </NavLink>
            <NavLink to="/contact" className="nav-link nav-pad-last" onClick={isMobileView ? handleNavClick : null}>
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default NavBar;
