import React, { useState } from 'react';
import { Row, Col, Card, Container, Modal } from 'react-bootstrap';
import { productarray } from '../../utils/utils';
import { Helmet } from 'react-helmet';
import './ProductPage.css';

const ProductPage = () => {
    const [show, setShow] = useState({ open: false, modeldata: null });

    const handleClose = () => {
        setShow({ open: false });
    };

    const handleShow = (data) => {
        setShow({ open: true, modeldata: data });
    };

    return (
        <div>
            <Helmet>
                <title>Cuplock Scaffolding Hire in Chennai, Cuplock Scaffold Rental</title>
                <meta name="description" content="Check out our wide range of high-quality scaffolding products. Contact us to get a quote." />
                <meta name="keywords" content="scaffolding, construction, products, high-quality, scaffoldingrentals, scaffoldingpipes, scaffoldingboard, Aps scaffolding, baseplate platform jolly" />
                {/* Add more SEO-related meta tags as needed */}
            </Helmet>
            <section className="hero-img">
                <div className="container contain h-100">
                    <div className="hero_heading text-center mb-2">
                        <h1>We provide Best Scaffolding hire Service & Product In the market Because We don't Compromise in Quality</h1>
                    </div>
                    <div className="white-text px-4 py-1 rounded-3">
                        <h2>“We always keep your trust”</h2>
                    </div>
                </div>
            </section>
            <section>
                <Container>
                    <h3 className="text-center mt-4 mb-5 product-head">Our Products</h3>
                    <Row xs={1} sm={2} md={3} lg={3} xl={4} className="g-4">
                        {productarray.map((data) => (
                            <Col className="p-4" key={data.id}>
                                <Card className="list-pro h-100" onClick={() => handleShow(data)}>
                                    <Card.Body className="p-5 cd-body">
                                        <img src={data.images} alt="product" className="w-100" />
                                    </Card.Body>
                                    <Card.Footer className="card-foo h-100">
                                        <h5 className="text-center">{data.productname}</h5>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Modal show={show.open} size="md" backdrop="static" keyboard={false} centered onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <div className="p-3 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-row align-items-center">
                                <div className="icon">
                                    <img src={show.modeldata?.images} alt="product" className="w-100" />
                                </div>
                                <div className="ms-2 c-details">
                                    <h3 className="heading">{show.modeldata?.productname}</h3>
                                </div>
                            </div>
                            <a href="/contact" className="badge">
                                <span>GET QUOTE</span>
                            </a>
                        </div>
                        <div className="mt-5">
                            <p>{show.modeldata?.description}</p>
                        </div>
                    </div>
                </Modal>
            </section>
        </div>
    );
};

export default ProductPage;
