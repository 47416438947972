
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeComponent from './components/HomeComponent/HomComponent';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/shared/Layout/Layout';
import ProductPage from './components/productpagecomponent/ProductPage';
import ContactForm from './components/Contact/ContactForm';
import About from './components/About/About';


function App() {
  
  return (
    <Routes>
      <>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomeComponent />} />
          <Route path='/products' element={<ProductPage />} />
          <Route path='/contact' element={<ContactForm />} />
          <Route path='/about' element={<About/>} />
         
        </Route>
      </>
    </Routes>
  
  );
}

export default App;
