import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import images from '../../assets/images/image';
import './About.css'; // Assuming your CSS file is named About.css

const About = () => {
    const cliimages = [images.clientdta, images.clientinnovative, images.clientjt, images.clientsm, images.clientsmc, images.smrclient, images.anitechclient, images.prakashclient];
    const cliimages2 = [images.clientinnovative, images.clientdta, images.clientsm, images.clientjt, images.smrclient, images.clientsmc, images.prakashclient, images.anitechclient];

    return (
        <div className="aboutt">
            <Helmet>
                <title>Scaffolding Hire in Chennai, Shed Work in Chennai</title>
                <meta name="description" content="Narayanasamy Industries is a scaffolding company located in Chennai, providing safe and reliable scaffolding equipment for construction and maintenance projects. Our scaffolding materials are customizable and versatile, allowing us to tailor our solutions to meet the specific needs of each project." />
                <meta name="keywords" content="Narayanasamyindustries, vanagaram, scaffolding contractors, scaffolding rentals" />
            </Helmet>
            <section className="py-xl-5">
                <Container className="mt-2">
                    <Row>
                        <Col lg={4}>
                            <div>
                                <hr style={{ width: "27%", border: "2px solid #665bac" }} className="mb-4" />
                                <h2 className="story-txt">Our Story</h2>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <p>Narayanasamy Industries is a scaffolding company located in Mogappair West, Chennai. We provide safe and reliable scaffolding equipment for construction and maintenance projects. Our scaffolding materials are customizable and versatile, allowing us to tailor our solutions to meet the specific needs of each project. With a commitment to quality, safety, and efficiency, our experienced team delivers, sets up, and dismantles the scaffolding equipment quickly and professionally. Contact us today for all your scaffolding rental needs in Chennai. Our company has been providing scaffolding rental services in Chennai for several years, and we have built a reputation for reliability, safety, and quality.</p>
                            <p>We offer a wide range of scaffolding materials for rent, including tube and coupler scaffolding, system scaffolding, and frame scaffolding. Our equipment is made from high-quality materials and is maintained to the highest standards to ensure safety and durability.</p>
                            <p>Our team of experienced professionals has the expertise to provide customized solutions to meet the specific needs of each project. Whether it is a small repair or a large construction project, we can provide scaffolding that is tailored to fit your requirements.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="client-section mb-5 py-xl-2">
                <div className="client-title">
                    <h4>Our Valuable Clients</h4>
                </div>
                <div className="logos_slider">
                    <div className="overflow">
                        <div className="logos_wrapper">
                            {cliimages.map((dat, index) => (
                                <div className="logo_item" key={index}>
                                    <img src={dat} alt={`Client ${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="overflow reverse">
                        <div className="logos_wrapper">
                            {cliimages2.map((dat, index) => (
                                <div className="logo_item" key={index}>
                                    <img src={dat} alt={`Client ${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
