import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Row, Col, Card, Container, CardGroup } from "react-bootstrap";
import "./HomComponent.css";
import images from "../../assets/images/image";
import { Helmet } from "react-helmet";

const HomeComponent = () => {
  let location = useLocation();
  
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const services = [
    {
      image: images.steel,
      title: "Centring Materials",
    },
    {
      image:
        "https://5.imimg.com/data5/PW/XS/MY-48195571/aps-unit-scaffolding-500x500.jpg",
      title: "Aps Scaffolding System",
    },
    {
      image: images.valve,
      title: "Scaffolding Pipe & Fittings",
    },
    {
      image: images.concrete,
      title: "Scaffolding Accessory",
    },
  ];

  const scaffoldingservice = [
    {
      id: 1,
      title: "Scaffolding Rental",
      para: "Our team works closely with clients to determine the appropriate scaffolding structure needed for their project and provides safe and reliable scaffolding rentals."
    },
    {
      id: 2,
      title: "Scaffolding Installation",
      para: "Our team of experienced professionals carefully plans and executes the installation process to ensure a stable and secure platform for workers to perform their tasks at various heights."
    },
    {
      id: 3,
      title: "Scaffolding Dismantling",
      para: "Provide dismantling services for construction scaffolding  which includes the safe and efficient process of disassembling and removing the temporary framework used in construction or maintenance work."
    },
    {
      id: 4,
      title: "Scaffolding Maintenance",
      para: "We also provide scaffolding maintenance services, which includes regular inspections, repairs, and upkeep of scaffolding structures to ensure their safety and reliability."
    }
  ]

  return (
    <>
      <Helmet>
        <title>Scaffolding Contractors in Chennai, Scaffolding Rental in Chennai</title>
        <meta name="description" content="Narayasamy Industries provides high-quality scaffolding rentals in Chennai. Rent Aps scaffolding, pipe scaffolding, and more." />
        <meta name="keywords" content="scaffolding, scaffolding installation, scaffolding maintenance, scaffolding dismantling, high-quality, scaffolding rentals, scaffolding pipes, scaffolding board, Aps scaffolding" />
      </Helmet>
      <section className="hero">
        <Row className="g-0 hero-row">
          <Col lg={1}></Col>
          <Col
            lg={5}
            md={12}
            className="hero-col d-flex align-items-center justify-content-center"
          >
            <div className="cop text-center px-xl-4">
              <h1 className="text-hero-bold hero-title-txt">
                Get the Best Quality Construction Scaffolding Material
              </h1>
              <p className="text-hero-regular hero-txt">
                At our company, we provide the best quality construction
                scaffolding material to meet your needs. Contact us today to get
                started!
              </p>
              <div className="cta">
                <a href="/contact" className="btn ctc-btn rounded-4">
                  Contact Us
                </a>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="hero-image">
              <img
                src={images.herosection}
                className="heroimage-1 "
                alt="img"
              />
            </div>
          </Col>
        </Row>
      </section>

      <section className="second-section">
        <Container className="px-xl-3">
          <div className="second-title">
            <h2>
              'Unlock the Power of Construction <br></br> Scaffolding
              Services'
            </h2>
          </div>
          <Row className="second-row" >
            <CardGroup>
              {scaffoldingservice.map((dat) => (
                <Col xl={3} lg={4} md={6} sm={6} key={dat.id}>
                  <Card className="card-hero pt-4 h-100">
                    <img
                      src={images.downarrow}
                      alt="down-arrow"
                      className="downarrow"
                    ></img>
                    <h4 className="card-title text-center">{dat.title}</h4>
                    <Card.Body>
                      <p className="card-txt">{dat.para}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </CardGroup>
          </Row>
        </Container>
      </section>

      <section className="third-section">
        <Container>
          <Row className="third-row">
            <div className="third-row-title">
              <h4 className="third-title">
                Our construction scaffolding material <br /> service offers the
                best service in the <br /> industry, providing customers with a{" "}
                <br /> reliable and safe scaffolding solution.
              </h4>
            </div>

            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Safety</h4>
                  <p className="card2-txt">
                    Construction scaffolding material offers safety for workers,
                    as it is designed to provide a stable platform for workers
                    to work on.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Durability</h4>
                  <p className="card2-txt">
                    Construction scaffolding material is designed to be durable
                    and can withstand heavy use in a wide range of weather
                    conditions.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Versatility</h4>
                  <p className="card2-txt">
                    Construction scaffolding material is versatile and can be
                    used in a variety of applications, from simple tasks to
                    complex projects.
                  </p>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="fourth-row">
            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Ease of Use</h4>
                  <p className="card2-txt">
                    Construction scaffolding material is easy to use and requires
                    minimal setup, allowing workers to quickly and easily begin
                    work.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Cost-Effective</h4>
                  <p className="card2-txt">
                    Construction scaffolding material is cost-effective and can
                    be used for a variety of applications, making it a great
                    choice for businesses to save money.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="card2">
                <div className="img-div">
                  <img
                    src={images.downarrow}
                    alt="down-arrow"
                    className="downarrow2"
                  ></img>
                </div>
                <div className="card2-txt">
                  <h4 className="card2-title">Time Saving</h4>
                  <p className="card2-txt">
                    Construction scaffolding material is time-saving and can be
                    quickly and easily set up, allowing workers to start their
                    projects quickly and efficiently.
                  </p>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="service" className="px-xl-5 py-3">
        <Container>
          <h2 className="text-service-bold text-center">Our Service</h2>
          <p className="text-service-regular text-center1">
            Hire Scaffolding Materials at Quality Price at Chennai Materials
            supply for centering sheet, column box, cuplock, and other related
            materials for rent in All Over Tamilnadu. We have a wide range of
            products available at very reasonable rates. Contact us for more
            details!
          </p>
          <Row className="mt-5 g-0">
            {services.map((dat, index) => (
              <Col className="d-flex justify-content-center" key={index}>
                <div className="box">
                  <div
                    className="round-div mb-3"
                    style={{ backgroundImage: `url(${dat.image})` }}
                  ></div>
                  <p className="service-subtxt">{dat.title}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="sub-footer">
        <Container>
          <Row>
            <Col lg={12} className="subfooter-col">
              <h2 className="subfooter-title">
                Get the Best Scaffolding <br /> Service
              </h2>
              <p className="subfooter-subtitle">We provide the best service in the industry for construction scaffolding materials.<br /> Contact us today to learn more!</p>
              <a href="/contact" className="ctf-btn">Leave Message</a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomeComponent;
