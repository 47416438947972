
import React from 'react';
import NavBar from '../Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
    return (
        <div className="main-div">
            <ToastContainer
                position="top-center"
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                closeButton={false}
                draggable={false}
                autoClose={5000}
                hideProgressBar
                theme="light"
            />
            <NavBar />
            <div className="content-div">{<Outlet />}</div>
            <Footer />
        </div>
    );
}

export default Layout;